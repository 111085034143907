.named-fence-block {
  position: relative;
}

.named-fence-filename {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  line-height: 1;
  padding-block: 0.381966em; /* φ⁻² */
  padding-inline: 0.618934em; /* φ⁻¹ */

  font-weight: 600;
  color: var(--color-text);
  background-color: var(--color-background-code);
  opacity: 0.9;

  border-start-end-radius: 0.236068em; /* φ⁻² */
  border-end-start-radius: 0.236068em; /* φ⁻² */
}
