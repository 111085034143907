:root {
  --font-text: serif;
  --font-text-emphasized: system-ui, BlinkMacSystemFont, -apple-system, sans-serif;
  --font-code: ui-monospace, Consolas, Inconsolata, monospace;
  --font-ui: system-ui, BlinkMacSystemFont, -apple-system, sans-serif;
}

[lang]:where(:not([lang=""]):not(:lang(mul, und, zxx, mis))) {
  --font-text: serif;
  --font-text-emphasized: system-ui, BlinkMacSystemFont, -apple-system, sans-serif;
  --font-code: ui-monospace, Consolas, Inconsolata, monospace;
}

[lang]:where(:not([lang=""]):not(:lang(mul)):not(:lang(und)):not(:lang(zxx)):not(:lang(mis))) {
  --font-text: serif;
  --font-text-emphasized: system-ui, BlinkMacSystemFont, -apple-system, sans-serif;
  --font-code: ui-monospace, Consolas, Inconsolata, monospace;
}

[lang="ja"],
[lang="jpn-archaic"] {
  --font-text: "YuMincho +36p Kana", "Yu Mincho", serif;
  --font-text-emphasized: "Hiragino Sans", "Yu Gothic Medium", sans-serif;
  --font-code: CodeM, "Myrica M", Ricty, ui-monospace, monospace;
}

[lang="he"],
[lang="hbo"] {
  --font-text: "Noto Serif Hebrew", serif;
  --font-text-emphasized: "Noto Sans Hebrew", sans-serif;
}

html,
:where([lang]) { /* NOTE: サンセリフ体や等幅フォントが使われるべき要素に lang が設定されても適用されないように、詳細度を下げる。 */
  font-family: var(--font-text);
}

h1, h2, h3, h4, h5, h6,
dl > dt,
dl > div > dt,
th {
  font-family: var(--font-text-emphasized);

  /* h1 ruby > rt[lang] のようなケースで :where([lang]) { font-family: var(--font-text) } をカスケードする。text.css、horizontal.css、vertical.css、.horizontal/vertical.css にも同様の行がある。 */
  & :where([lang]) {
    font-family: var(--font-text-emphasized);
  }
}

code {
  font-family: var(--font-code);

  /* code の内部ではフォントの変更を無視する。 */
  & * {
    font-family: revert !important;
  }

  /* em */
  :is(
    h1, h2, h3, h4, h5, h6,
    dl > dt,
    dl > div > dt,
    em, cite, b, i
  ) & {
    font-family: var(--font-code);
  }
}

kbd {
  font-family: var(--font-ui);
}

sup, sub {
  font-family: var(--font-text-emphasized);
}

.ipa {
  /* lang にかかわらず同じ */
  font-family: system-ui, BlinkMacSystemFont, -apple-system, sans-serif;
}
