table {
  table-layout: auto;
  border-collapse: collapse;

  font-size: calc(39 / 44 * 100%);
  overflow-wrap: revert;
  margin-inline: auto;
  border-block: 2px solid var(--color-border);

  break-inside: avoid-column;
}

thead > tr:last-of-type,
table:not(:has(thead)) > tr:first-of-type {
  border-block-end: 1px solid var(--color-border);
}

th, td {
  /* NOTE: ブロック方向には line-height による half-leading がある。 leading-trim が一般的になったら `leading-trim: both; padding: 1em` で置き換える。 */
  padding-block: 0.5em;
  padding-inline: 1em;
}

th {
  font-weight: unset;
  word-break: keep-all;
}
