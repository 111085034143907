body {
  margin: 0;
}

pre {
  margin-block: 1em;
  margin-inline: 0;
}

.revert, .r {
  all: revert;
}
